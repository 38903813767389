<template>
  <v-container>
    <SessionExpired />
    <!--    <SessionCreditsFooter />-->
  </v-container>
</template>

<script>
import { projectCustomComponent } from '@utils/project.js'
export default {
  page: {
    title: 'Sessione Scaduta',
  },
  components: {
    // SessionCreditsFooter: () =>
    //   projectCustomComponent('SessionCreditsFooter.vue'),
    SessionExpired: () => projectCustomComponent('SessionExpired.vue'),
  },
}
</script>
